/*
 * Iconists Central Icon System.
 * Distribution of this file is not permitted.
 *
 * For more information, see:
 * https://iconists.co/license
 */
import { createIcon } from "../utilities/create-icon";

export const CrossLarge = createIcon({
  name: "cross-large",
  type: "line",
  paths: ["M4.75 4.75L19.25 19.25M19.25 4.75L4.75 19.25"],
  pathProps: {
    stroke: "currentColor",
    strokeWidth: "1.5",
    strokeLinecap: "round",
  },
});
